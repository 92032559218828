const Index = () => import('./components/index');
const Show = () => import('./components/show');

const FontsIndex = () => import('./components/fonts/index.vue');

export default [
  {
    path: '/marketing',
    name: 'marketing.index',
    meta: {
      title: 'Marketing',
      shield: 'marketing.index',
    },
    component: Index,
  },
  {
    path: '/marketing/:id',
    name: 'marketing.show',
    meta: {
      title: 'Marketing',
      shield: 'marketing.show',
    },
    component: Show,
  },
  {
    path: '/marketing/fonts',
    name: 'marketing.fonts.index',
    meta: {
      title: 'Marketing Fontes',
      shield: 'marketing.fonts.index',
    },
    component: FontsIndex,
  },
];
