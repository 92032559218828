<script>
let typingTimer;

export default {
  name: 'Select2',

  props: {
    name: { String, required: true },
    customLabel: { Function },
    labelSelect: null,
    label: null,
    info: { String, default: '' },
    trackBy: { String, required: true },
    required: { Boolean, default: false },
    selected: null,
    placeholder: { String },
    options: { Array, default: () => [] },
    multiple: { Boolean, default: false },
    v: { default: '' },
    textValidations: { default: '' },
    formSm: { default: false },
    errorInPlaceholder: { type: Boolean, default: false },
  },

  data() {
    return {
      model: [],
    };
  },

  computed: {
    vName() {
      if (this.each) {
        return this.v[this.name];
      }
      return this.v.form[this.name];
    },

    errors() {
      if (this.v) {
        if (this.vName.$dirty && this.vName.$invalid) {
          const errors = [];
          Object.keys(this.textValidations).forEach(error => {
            errors.push(this.textValidations[error]);
          });
          return errors[0];
        }
      }
      return '';
    },

    isDanger() {
      if (this.v) {
        if (this.vName) {
          if (!this.model && !Object.keys(this.textValidations).find(x => x === 'required')) {
            return false;
          }
          return this.vName.$dirty && this.vName.$invalid;
        }
        return false;
      }
      return false;
    },
  },

  watch: {
    options() {
      this.model = [];
      window.$(`#select_${this.name}`).empty().trigger('change');
      this.format();
    },

    selected() {
      this.format();
    },
  },

  methods: {
    success() {
      this.$emit('blur');
      if (this.v) {
        setTimeout(() => {
          this.isSuccess = this.vName ? !this.vName.$invalid : !!this.model;
        }, 500);
      }
    },

    delayTouch() {
      if (this.v) {
        if (this.vName) {
          this.vName.$reset();
        }
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => (this.vName ? this.vName.$touch() : ''), 1000);
      } else {
        this.valid = false;
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          this.valid = true;
        }, 1000);
      }
    },

    getAttributesData(options) {
      if (this.customLabel) {
        return this.customLabel(options);
      }

      if (typeof this.labelSelect === 'object') {
        let items = options;
        this.labelSelect.forEach(item => {
          items = items[item];
        });

        return items;
      }
      return options[this.labelSelect];
    },

    format() {
      this.options.forEach(key => {
        this.model.push({ id: key[this.trackBy], text: this.getAttributesData(key) });
      });

      const select = window.$(`#select_${this.name}`);
      select
        .select2({
          placeholder: this.placeholder,
          theme: 'bootstrap4',
          width: '100%',
          language: 'pt-BR',
          allowClear: true,
          data: this.model,
        })
        .on('change', () => {
          if (this.options.length) {
            this.$emit('input', select.val() || null);
          }
        });

      if (this.multiple) {
        if (
          [...this.selected].sort().join(',') !==
          [...window.$(`#select_${this.name}`).val()].sort().join(',')
        ) {
          window.$(`#select_${this.name}`).val(this.selected).trigger('change');
        }
      } else {
        window.$(`#select_${this.name}`).val(this.selected).trigger('change');
      }
    },
  },

  mounted() {
    this.format();
  },

  destroyed() {
    window.$(`#select_${this.name}`).off().select2('destroy');
  },
};
</script>

<template>
  <div
    :class="{
      'has-error': isDanger,
      'form-group': labelSelect && !formSm,
    }"
  >
    <label :for="this.name" v-if="label">
      {{ label }}
      <span class="text-danger" v-if="required"> *</span>
      <span v-if="info" v-tooltip="info">
        <InformationIcon :size="16" class="text-info" />
      </span>
    </label>
    <select
      :id="`select_${name}`"
      :class="{
        'form-control-sm': formSm,
        'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
      }"
      class="select2-hidden-accessible"
      v-bind:name="name"
      v-bind:multiple="multiple"
    >
      <option value=""></option>
    </select>

    <div v-if="!errorInPlaceholder">
      <div :key="index" v-for="(error, index) in Object.keys(textValidations)">
        <div
          class="invalid-feedback"
          style="display: block"
          v-if="v ? vName && vName[error] === false && vName['$dirty'] : false"
        >
          &times; {{ textValidations[error] }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.has-error .selection .select2-selection {
  border-color: #dc3545 !important;
}
</style>
